<template>
    <div class="image-with-text">
      <img :src="imageSrc" alt="Image" />
      <div class="text-overlay">{{ tit }}</div>
      <div class="text-overlay1">{{ text }}</div>
      <div class="text-overlay2">{{ tit1 }}</div>
      
    </div>
  </template>
  
  <script>
  export default {
    name: 'ImageWithText',
    props: {
      imageSrc: String, 
      tit:String,
      text: String, 
      tit1:String,
    },
  }
  </script>
  
  <style scoped>
  .image-with-text {
    position: relative;
    display: inline-block;
    margin: 0;
    padding: 0;
  }
  
  .image-with-text img {
    width: 100vw; 
  max-height: 600px;
    
  }
  .image-with-text::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(27, 51, 189, 0.295); /* 浅灰蓝色，透明度可调 */
  z-index: 1; /* 确保伪元素位于图片之上 */
  margin: 0;
    padding: 0;
}
  .text-overlay {
    position: absolute;
    bottom: 0;
    left: 130px;
   
    top:300px;
    color: white;
    text-align: center;
    padding: 10px;
    font-size:25px;
    z-index: 2;
  }
  .text-overlay1 {
    position: absolute;
    bottom: 0;
    left: 30px;
    
    top:450px;
    color: white;
    text-align: center;
    padding: 0 500px 0 0;
    font-size:80px;
    z-index: 2;
  }
  .text-overlay2 {
    position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); 
  color: white;
  text-align: center;
  font-size: 80px;
  z-index: 2;
  }


  @media (max-width: 1000px){
    .text-overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    right:0;
    top:15%;
    color: white;
    text-align: center;
    padding: 10px;
    font-size:15px;
  }
  .text-overlay1 {
  
    bottom: 0;
    left: 0;
    right:0;

    top:30%;

    color: white;
    
    padding:0 50px;
    font-size:30px
  }
  .text-overlay2 {
  
  
  font-size:30px
}


    
  }
  @media (max-width: 2000px){
    .text-overlay {
   
    font-size:20px;
  }
  .text-overlay1 {
  
    font-size:30px
  }

  .text-overlay2 {
  
  
  font-size:30px
}


    
  }
  
  </style>
  