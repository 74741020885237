<template>
   
  <div class="topS">
  
     <nav class="top-bar2">
  
     
      <div class="nav-links1">
       
        <TimeData1></TimeData1>
      </div>
  
  
    </nav>
  
         <div class="logo-container">
          <router-link to="/">
            <h1 class="logoPC">Jefferies</h1>
          </router-link>
        </div> 
        <nav class="top-bar1">
  
  
          <div class="nav-links1">
  

  <div class="nav-item" @mouseover="setActiveMenu('about')" @mouseleave="clearActiveMenu">
  <h3 class="nav-linkpc">우리의 서비스</h3>
  <div v-if="activeMenu === 'about'" class="submenu1">
    <a href="https://www.jfrsgp.com/#/pages/login" class="nav-linkpc1"><ion-icon name="chevron-forward-outline"></ion-icon>외자기관 VIP 전용</a>
    <router-link to="/JeF1" class="nav-linkpc1"><ion-icon name="chevron-forward-outline"></ion-icon>투자</router-link>
    <router-link to="/JeF2" class="nav-linkpc1"><ion-icon name="chevron-forward-outline"></ion-icon>글로벌 리서치 및 전략</router-link>
     
    <router-link to="/JeF3" class="nav-linkpc1"><ion-icon name="chevron-forward-outline"></ion-icon>공모주/IPO</router-link>
     
    <router-link to="/JeF4" class="nav-linkpc1"><ion-icon name="chevron-forward-outline"></ion-icon>고정 소득</router-link>
     
    <router-link to="/JeF5" class="nav-linkpc1"><ion-icon name="chevron-forward-outline"></ion-icon>대체 자산 관리</router-link>
     
    <router-link to="/JeF6" class="nav-linkpc1"><ion-icon name="chevron-forward-outline"></ion-icon>재산 관리</router-link>

      </div>
      </div>


      <div class="nav-item" @mouseover="setActiveMenu('about')" @mouseleave="clearActiveMenu">
  <h3 class="nav-linkpc">회사 소개</h3>
  <div v-if="activeMenu === 'about'" class="submenu1">
    <router-link to="/JeF7" class="nav-linkpc1"><ion-icon name="chevron-forward-outline"></ion-icon>문화 및 성장 이야기</router-link>
    <router-link to="/JeF8" class="nav-linkpc1"><ion-icon name="chevron-forward-outline"></ion-icon>회의 및 이벤트</router-link>
  <router-link to="/LeaderS" class="nav-linkpc1"><ion-icon name="chevron-forward-outline"></ion-icon>팀을 이끌다</router-link>
    

      </div>
      </div>


      <div class="nav-item" @mouseover="setActiveMenu('about')" @mouseleave="clearActiveMenu">
  <h3 class="nav-linkpc">채용 정보</h3>
  <div v-if="activeMenu === 'about'" class="submenu1">
    <router-link to="/JeF9" class="nav-linkpc1"><ion-icon name="chevron-forward-outline"></ion-icon>혜택 및 프로그램</router-link>
    

      </div>
      </div>














      <div class="nav-item" @mouseover="setActiveMenu('about')" @mouseleave="clearActiveMenu">
  <h3 class="nav-linkpc">국내외 증시</h3>
  <div v-if="activeMenu === 'about'" class="submenu1">
    <router-link to="/PageData3" class="nav-linkpc1"><ion-icon name="chevron-forward-outline"></ion-icon>국내 증시</router-link>
    <router-link to="/PageData2" class="nav-linkpc1"><ion-icon name="chevron-forward-outline"></ion-icon>해외 증시</router-link>
      
  </div>
      </div>

      <div class="nav-item" @mouseover="setActiveMenu('about')" @mouseleave="clearActiveMenu">
  <h3 class="nav-linkpc">실시간 시황</h3>
  <div v-if="activeMenu === 'about'" class="submenu1">
    <router-link to="/PageData4" class="nav-linkpc1"><ion-icon name="chevron-forward-outline"></ion-icon>실시간 시황</router-link>
       </div>
      </div>

      <div class="nav-item" @mouseover="setActiveMenu('about')" @mouseleave="clearActiveMenu">
  <h3 class="nav-linkpc">종목체크</h3>
  <div v-if="activeMenu === 'about'" class="submenu1">
    <router-link to="/PageData5" class="nav-linkpc1"><ion-icon name="chevron-forward-outline"></ion-icon>종목종합소식</router-link>
    <router-link to="/PageData6" class="nav-linkpc1"><ion-icon name="chevron-forward-outline"></ion-icon>상승종목</router-link>
    <router-link to="/PageData7" class="nav-linkpc1"><ion-icon name="chevron-forward-outline"></ion-icon>급등종목</router-link>
    <router-link to="/PageData8" class="nav-linkpc1"><ion-icon name="chevron-forward-outline"></ion-icon>상한가 종목</router-link>
    <router-link to="/PageData9" class="nav-linkpc1"><ion-icon name="chevron-forward-outline"></ion-icon>거래량상위종목</router-link>
           
  </div>
      </div>

      <div class="nav-item" @mouseover="setActiveMenu('about')" @mouseleave="clearActiveMenu">
  <h3 class="nav-linkpc">시장지표</h3>
  <div v-if="activeMenu === 'about'" class="submenu1">
        <router-link to="/PageData10" class="nav-linkpc1"><ion-icon name="chevron-forward-outline"></ion-icon>전종목시세</router-link>
        <router-link to="/PageData11" class="nav-linkpc1"><ion-icon name="chevron-forward-outline"></ion-icon>투자주체별 동향</router-link>
        <router-link to="/PageData12" class="nav-linkpc1"><ion-icon name="chevron-forward-outline"></ion-icon>외국인매매동향</router-link>
        <router-link to="/PageData13" class="nav-linkpc1"><ion-icon name="chevron-forward-outline"></ion-icon>외   환</router-link>
        <router-link to="/PageData14" class="nav-linkpc1"><ion-icon name="chevron-forward-outline"></ion-icon>채권/금리</router-link>
        <router-link to="/PageData15" class="nav-linkpc1"><ion-icon name="chevron-forward-outline"></ion-icon>국내경제지표</router-link>
  
        
      </div>
      </div>

      <div class="nav-item" @mouseover="setActiveMenu('about')" @mouseleave="clearActiveMenu">
  <h3 class="nav-linkpc">공모주 청약일정</h3>
  <div v-if="activeMenu === 'about'" class="submenu1">
    <router-link to="/PageData16" class="nav-linkpc1"><ion-icon name="chevron-forward-outline"></ion-icon>공모주 청약일정
</router-link>
        <!-- <router-link to="/PageData17" class="nav-linkpc1"><ion-icon name="chevron-forward-outline"></ion-icon>현장 리포터
</router-link>
        <router-link to="/PageData18" class="nav-linkpc1"><ion-icon name="chevron-forward-outline"></ion-icon>은행은 지금
</router-link> -->
        
      </div>
      </div>

      <div class="nav-item" @mouseover="setActiveMenu('about')" @mouseleave="clearActiveMenu">
  <h3 class="nav-linkpc">IPO 캘린더</h3>
  <div v-if="activeMenu === 'about'" class="submenu1">
    <router-link to="/PageData19" class="nav-linkpc1"><ion-icon name="chevron-forward-outline"></ion-icon>IPO 캘린더</router-link>

        
      </div>
      </div>

  
  
       
     
  
  
  
  
  
      </div>
      
      </nav> 
    
  
  
  
  
    </div>
  
  
  
  
  
  
      <div class="content">
        <router-view></router-view> 
      </div>
    
    
    
        <br>
   
     
   
    
      <div>
        
        <BackToTopButton />
        <BottomNav></BottomNav>
      </div>
      
    </template>
  
  
  <script>
  import BackToTopButton from '../components/BackToTopButton.vue';
  import TimeData1 from './TimeData1.vue';
  import BottomNav from './BottomNav.vue';
  
  export default {
    name: 'App',
    components: {
      BackToTopButton,
      TimeData1,
      BottomNav,
  },
  
  //任务栏的方法
    data() {
      return {
        isNavOpen: false,
        activeMenu: null,
  
      };
    },
    methods: {
      clearActiveMenu() {
      this.activeMenu = null;
    },
      setActiveMenu(menuItem) {
      this.activeMenu = menuItem;
    },
  
    
  
  
   
    }
  };
  
  
  
  </script>
  
  
  
  
  
  
  <style scoped>
  .topS{
    position: relative;
  }
  
  .top-bar2 {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    /* padding: 10px 10px; */
    background: linear-gradient(to left, #ffffff, #ffffff);
    
    /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); */
    height: 50px;
  
  }
  
  
  
  
  .top-bar1 {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 10px 20px;
    background: linear-gradient(to left, #ffffff, #ffffff);
    
    box-shadow: 0 4px 0 0 rgba(0, 0, 0, 0.2);
    height: 50px;
  }
  
  .logo-container {
    flex: 1;
  }
  
  
  
  .nav-links1 {
    /* display: flex;
    flex: 5;
    justify-content: flex-start;
     */
  
   
    /* justify-content: space-between; 靠右 */
   
  
  
    display: flex;
    justify-content: flex-end; 
    align-items: center; /* 垂直居中 */
    width: 100%; /* 占满整个容器宽度 */
  }
  
  
  .translate-button-container1 {
    flex: 3;
    display: flex;
    justify-content: flex-end;
  }
  
  
  .logoPC {
   
    display: block; 
    max-width: 190px;  
    max-height: 100px;  
    height: auto;   
    left: 20px;
    position: absolute;
    top: 2px;
    font-size: 48px;
    color: #000;
  }

  .logoPC:hover{
    color: #ccc;
    transition: color 0.8s ease;
  }
  
  
  
  
  
  
  .nav-item {
    position: relative;
  
    display: flex;
    flex-direction: column; /* 子元素垂直排列 */
    align-items: center; /* 子元素在.nav-item内垂直居中 */
    justify-content: center; /* 子元素在.nav-item内水平居中 */
    margin: 0 1px; /* 每个.nav-item之间的间距，可根据需要调整 */
  }
  
  .submenu1 {
    position: absolute;
    /* position: absolute; */
    top: 100%; /* 将子菜单放在导航项的正下方 */
    left: 0;
    /* width: 100%; */
    background-color: #ffffff; 
    border: 1px solid #ccc; 
    display: none; 
    z-index: 2000;
    border-radius: 10px;
    white-space: nowrap;
  }
  
  
  .nav-item:hover .submenu1,
  .submenu1:hover {
    display: block;
    
  }
  
  
  .nav-login {
   
  
  color: #f78316;
  padding: 10px 20px; /* 增加一些内边距以改善点击区域 */
  text-decoration: none;
  width: 100%; /* 链接宽度占满父容器 */
  box-sizing: border-box;
  margin: 0; /* 移除外边距 */
  transition: color 0.3s ease;
  cursor: pointer;
  /* border-bottom: 1px solid #cccccc;  */
  padding: 10px; /* 保持原有的内边距 */
  
  border-radius: 10px;
   transition: background-color 0.3s ease; /* 添加平滑的过渡效果 */
  transition: font-size 0.3s ease; /* 添加平滑的过渡效果 */
  
  }
  
  
  .nav-login:hover {
  background-color: #a7a7a7; 
 
  transition: background-color 0.3s ease; /* 添加平滑的过渡效果 */

  }
  
  
  
  
  
  
  
  
   
  
   /* 导航链接样式 */
   .nav-linkpc {
  

     display: block; 
    color: #000000;
    padding: 10px 13px; /* 增加一些内边距以改善点击区域 */
    text-decoration: none;
    width: 100%; /* 链接宽度占满父容器 */
    box-sizing: border-box;
    margin: 0; /* 移除外边距 */
    transition: color 0.3s ease;
    cursor: pointer;
    /* border-bottom: 1px solid #cccccc;  */
  
    border-radius: 10px;
    font-size: 15px;
    
   }
  
  
   .nav-linkpc:hover {
    background-color: #ffffff; 
    color: #000000;
    font-size: larger;
    transition: background-color 0.8s ease; /* 添加平滑的过渡效果 */
    transition: font-size 0.3s ease; /* 添加平滑的过渡效果 */
  }
  
  
  .nav-linkpc1 {
  
    
   
  display: block;
  color: #000000;
  padding: 10px 20px; /* 增加一些内边距以改善点击区域 */
  /* text-decoration: none; */
  width: 100%;
  box-sizing: border-box;
  margin: 0; 
  transition: color 0.3s ease;
  cursor: pointer;
  /* border-bottom: 1px solid #cccccc;  */
  padding: 10px; /* 保持原有的内边距 */
  
  font-size: 12px;
   transition: background-color 0.3s ease; 
  transition: font-size 0.3s ease; 
  
  }
  
  
  .nav-linkpc1:hover {
  background-color: #ffffffa1; /* 悬停时的背景颜色变为浅灰色 */

  font-size: larger;
    transition: background-color 0.8s ease; /* 添加平滑的过渡效果 */
    transition: font-size 0.3s ease; /* 添加平滑的过渡效果 */
  border-radius: 5px;
  }
  
  
  
  
  
  
  .divider {
      width: 80%; /* 设置线条宽度为80% */
      border: none; /* 移除默认边框 */
      height: 1px; /* 设置线条高度 */
      background-color: grey; /* 设置线条颜色为灰色 */
      margin: 0 auto; /* 水平居中并移除默认外边距 */
    }
   
  
  
  
  </style>
  