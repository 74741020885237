<template>
  <div class="maxw">
    <MobileUI v-if="isMobile"/>
    <DesktopUI v-else/>
  </div>
</template>

<script>
import { ref, onMounted, onUnmounted } from 'vue';
import MobileUI from './components/PonzUI.vue';  
import DesktopUI from './components/PonzUIpc.vue'; 


export default {
  components: {
    MobileUI,
    DesktopUI
  },
  setup() {
    const isMobile = ref(window.innerWidth < 1030); 

    // 检测屏幕尺寸的函数
    function checkScreenSize() {
      isMobile.value = window.innerWidth < 1030;
    }

    onMounted(() => {
      window.addEventListener('resize', checkScreenSize);
    });

    onUnmounted(() => {
      window.removeEventListener('resize', checkScreenSize);
    });

    return {
      isMobile
    };
  }
};
</script>






<style>


.maxw{
  width: 100%;
  max-width: 100%; 
  margin: 0 auto; 

}




.home-page {
  /* 首页样式 */
  position: relative;
  
  
  align-items: center;
  height: auto;

}




.gfre{
width: 90%; /* 图片宽度将根据父容器的宽度自动调整 */
height: auto;
display: block; /* 让图片水平居中显示 */
margin: 0 auto; /* 设置左右外边距为 auto，实现水平居中 */
box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
}
.gfre1 {
width: 66%;
height: auto;
display: block; /* 让图片水平居中显示 */
margin: 0 auto; /* 设置左右外边距为 auto，实现水平居中 */
box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
}


.gfre2 {
width: 100%;
height: auto;
display: block; /* 让图片水平居中显示 */
margin: 0 auto; /* 设置左右外边距为 auto，实现水平居中 */

}



html, body {
  height: 100%; 
  margin: 0%; 
  padding: 0%;
  
  /* overflow-x: hidden; */

}

#app {
  display: flex;
  flex-direction: column;
  min-height: auto; 
}





.top-bar {
  display: flex;
  justify-content: flex-end; 
  /* justify-content: space-between;  */
  align-items: center;
  
 
   box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); 
 
background-color: white;

   position: relative;
   width: 100%;
   height: 60px;
   
 }
 


.logo-link {
  margin: auto;
  display: flex;
  justify-content: center; 
  align-items: center; 
 
}

.logo {
 
  display: block; 
  max-width: 150px;  
  max-height: 75px;  
  height: auto;   
  right: 3%;
  position: absolute;
  top: -10px;
}


 



.no-scroll {
  overflow: hidden;
  height: 100%;
}





 .side-nav {
  display: flex;
  flex-direction: column; 
  align-items: flex-start;

   position: fixed;
   top: 0;
 
   right: -100%; 
   width: 300px; 
   height: 100%;
   background-color: white;
   transition: right 0.3s ease; 
   box-shadow: 0 -2px 5px 0 rgba(0, 0, 0, 0.2);
   border-right: 2px solid #adadad; 
   z-index: 2000;
   overflow-y: auto;
 }
 .side-nav.open {
   right: 0; 
 
 }
 
 

 .nav-link {
  font-size: 1px;
  display: block;
  color: #000000;
  padding: 10px 20px; 
  text-decoration: none;
  width: 100%; 
  box-sizing: border-box;
  margin: 0; 
  transition: color 0.3s ease;
 

   transition: background-color 0.3s ease; 
  transition: font-size 0.3s ease;
  
 }


 .nav-link:hover {
  color: #5e5e5e; 
  /* font-size: larger; */
  transition:color 0.6s ease; /* 添加平滑的过渡效果 */
  transition: font-size 0.3s ease; /* 添加平滑的过渡效果 */

}


 /* 导航链接样式 */
 .nav-link1 {

  
 
display: block;
color: #000000;
padding: 10px 20px; 
text-decoration: none;
width: 100%; 
box-sizing: border-box;
margin: 0; 
transition: color 0.3s ease;



transition: background-color 0.3s ease; /* 添加平滑的过渡效果 */
transition: font-size 0.3s ease; /* 添加平滑的过渡效果 */

}


.nav-link1:hover {
  color: #9e9d9d; 

  transition:color 0.6s ease; /* 添加平滑的过渡效果 */

cursor: pointer;

}
















 .nav-title {

  width: 100%;
 
}
.nav-title1 {
  cursor: pointer;
  width: 100%;
 
}

.nav-title2 {
  background: none;  /* 移除背景 */
  border: none;      /* 移除边框 */
  cursor: pointer;   /* 添加鼠标指针样式 */
  /* padding: 5px;       */
  
}









/*底部任务栏*/ 
.bottom-bar {
  box-shadow: 0 -2px 5px 0 rgba(0, 0, 0, 0.2);
  background-color: #ffffff; /* 底部任务栏背景颜色 */
   color: rgb(2, 2, 2); /* 底部任务栏文字颜色 */
   text-align: center; /* 文字居中对齐 */

   padding: 10px; /* 内部填充 */
   /* position: absolute;

   position: fixed; */
   /* bottom: 0;
   left: 0; */
   width: 100%;
   
 }
 .footer-link {
   color: rgb(0, 0, 0); /* 超链接文字颜色 */
   text-decoration: none; /* 去除下划线 */
   margin: 0 10px; /* 两侧外边距 */
   font-weight: bold; /* 字体加粗 */
   padding: 5px 0; /* 增加垂直内边距 */
   line-height: 8; /* 调整行高 */
   font-size: 13px; /* 字体大小 */
 }
 









 

.nav-links-container {
  display: flex;
  flex-direction: row; 
  /* align-items: center;  */
  flex-wrap: wrap;
  margin: 20% 0;
  
}




@media (max-width: 1000px) {
  .nav-link{
    font-size: 15px;
    font-weight: bold;
  } 
  
  
  .nav-title {
    font-size: 30px; 
  }

  .nav-links-container {
    flex-direction: row; 
    /* flex-wrap: wrap;  */
  }

 
}

/* .dark-theme {
  background-color: black;
  color: white;
}

.dark-theme .TranslateButton,
.dark-theme .top-bar,
.dark-theme .tit2,
.dark-theme .tit111,
.dark-theme .p2,
.dark-theme .p1,
.dark-theme .tit1,
.dark-theme .tit,
.dark-theme .box-shadow,
.dark-theme .menu-button,
.dark-theme .side-nav,
.dark-theme .bottom-bar,
.dark-theme .top-bar,
.dark-theme .nav-link,
.dark-theme .nav-title,
.dark-theme .nav-title1,
.dark-theme .nav-title2,
.dark-theme .footer-link {
  background-color: black;
  color: white; 
  box-shadow: 5px 5px 10px rgba(255, 255, 255, 0.5); 
} */









.text-container111 {
  display: flex; 
  justify-content: space-between; /* 两侧的内容分布在容器的两端 */
  width: 100%;
}


.left-text{
  width: 50%;
  margin: 0 10px;
  transition: background-color 0.3s ease, color 0.3s ease;
  
}
.right-text {
  width: 50%; 
  margin: 0 10px;
  transition: background-color 0.3s ease, color 0.3s ease;
}





.table-container {
  display: table;
  width: 100%;
  border-collapse: collapse;
  
}

.table-row {
  display: table-row;
}

.table-cell {
  display: table-cell;
  padding: 10px;
  border: 1px solid #ccc;
}


.table-cell.header {
  background-color: #016a84;
  color: white;
}










.video-container {
    position: relative;
    width: 80%; /* 容器宽度，可以调整 */
    padding-top: 50%; /* 16:9比例的顶部内边距 (9 / 16 = 0.5625) */
    overflow: hidden;
    margin: auto;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.video-container video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

</style>