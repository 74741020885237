<template>
 
    <div class="home-page">
   

      <TimeData1 class="TimeData"></TimeData1>
      <image1 :image-src="imageSrc" tit="통찰력 있는 • 열정적인 • 하이 터치" text="Jefferies는 세계 최고의 풀 서비스 투자 은행 및 자본 시장 기관 중 하나입니다."></image1>
  
<div class="content-module">
    <div class="links">
      <h1 class="tit">제퍼리스는 모든 부문과 지역에 대한 깊은 전문 지식을 제공합니다.</h1>

      <hr style="width: 100%; border-color: rgb(231, 231, 231);">
<router-link to="/JeF1" class="link">투자은행</router-link>
<hr style="width: 100%; border-color: rgb(231, 231, 231);">
<router-link to="/JeF2" class="link">글로벌 연구 및 전략</router-link>
<hr style="width: 100%; border-color: rgb(231, 231, 231);">
<router-link to="/JeF3" class="link">주식</router-link>
<hr style="width: 100%; border-color: rgb(231, 231, 231);">
<router-link to="/JeF4" class="link">고정 소득</router-link>
<hr style="width: 100%; border-color: rgb(231, 231, 231);">
<router-link to="/JeF5" class="link">대체 자산 관리</router-link>
<hr style="width: 100%; border-color: rgb(231, 231, 231);">
<router-link to="/JeF6" class="link">재산 관리</router-link>
<hr style="width: 100%; border-color: rgb(231, 231, 231);">

    </div>
    <div class="image-container">
      <img src="../images/london.png" alt="Your Image">
    </div>
  </div>




<ContentCards></ContentCards>




    </div>
  </template>
  





  <style scoped>
.content-module {
  display: flex;
  flex-direction: column;
  background-color: #295e97; 
  color: white; 
  padding: 0;
  margin: 0;
}

.links {
  flex: 1;
  padding: 50px;
}

.link {
  display: block;
  margin: 10px 0;
  color: white; 
  text-decoration: none;
  font-size: 20px;
}
.link:hover{
  color: #afafaf;
  transition: color 0.5s ease;
}
.image-container {
  flex: 2;
  /* padding: 20px; */
}

.image-container img {
  width: 100%; 
  height: auto;
}

@media (min-width: 768px) {
  .content-module {
    flex-direction: row; /* 横向排列 */
  }

  .links {
    flex: 1; /* 链接区域占用可用空间 */
    text-align: left; /* 链接左对齐 */
  }

  .image-container {
    flex: 2; /* 图片区域占用双倍空间 */
    text-align: center; /* 图片居中对齐 */
  }
}





.TimeData{

  display: none;
}


.image-container {
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
  display: flex;
  border-radius: 10px;
 
}

.left-images {
  display: flex;
  flex-direction: column; /* 垂直排列图片 */
  width: 30%;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  background-color: rgb(255, 255, 255);

  margin: 0 50px;
  padding: 0 20px;
}
.right-images {
  display: flex;
  flex-direction: column; /* 垂直排列图片 */
  width: 30%;
  border-radius: 10px;
  margin: 0 50px;
}

.left-images img {
  width: 100%; 
  height: auto;
  margin-bottom:0px; 
  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);  */

  margin: 20px 0;
}

.right-image img {
  
  width: 97%; 
  height: auto;
  /* margin: 0 80px; */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); 
}



@media (max-width: 768px) {
  .image-container {
    flex-direction: column; 
    justify-content: center; 
  align-items: center; 
  display: flex;
  border-radius: 10px;
  }

  .left-images {
    width: 80%; 
    justify-content: center; 
  align-items: center; 
  display: flex;
  border-radius: 10px;

  }
  .right-image{
    width: 80%; 
    justify-content: center; 
  align-items: center; 
  display: flex;
  border-radius: 10px;
  }



  .TimeData{

display: block;
}
}


.left-section {
  flex: 1;
  text-align: center; /* 居中文字 */
}

.text-module {
  margin-bottom: 10px; /* 与图片的间距 */
  width: 95%;
  color: #000000;
  margin: 10px auto;
}

/* .title {
  color: rgb(33, 69, 230); 
  margin-bottom: 10px; 
  background-color: #fff;
  border-radius: 20px;
  padding:0 10px;
  font-size: 20px;
} */


</style>

  
  
  <script>
import TimeData1 from './TimeData1.vue';
import image1 from  './imagecom/image1.vue';
import ContentCards from  './ContentCards.vue';



export default {
  name: 'App',
  components: {
    image1,
    TimeData1,
    ContentCards,
},data() {
  return {
    imageSrc: require('@/components/imagecom/image1.jpg'),
  };
}
}

</script>











