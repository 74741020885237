<template>

<div class="content-module1">
    <h1 class="tit">Jefferies</h1>
      
    <slot>Jefferies is one of the world's leading full-service investment banking and capital markets firms.</slot>
      <br>
      <br>
      <br>
    <p>이메일: info@jfrsclub.com</p>
      <p>전화：010 – 2028 – 3765</p>
      <p>주소: 서울 영등포구 국제 금융로 2길 24번지</p>
    </div>


    <div class="content-module">
      <slot>© 2024 Jefferies Financial Group Inc.</slot>
    </div>
  </template>
  
  <script>
  export default {
    name: 'SimpleContentModule',
  }
  </script>
  
  <style scoped>


.content-module1 {
    background-color: #28349c;
    color: white; 
    padding: 20px;
    text-align: center;
   height: 300px;
  }
  .content-module {
    background-color: #111b74; 
    color: white; 
    padding: 20px; 
    text-align: center; 
   
  }
  </style>
  