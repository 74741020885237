<template>
    <div class="content-cards">
      <div class="card" v-for="(card, index) in cards" :key="index">
        <img :src="card.image" :alt="card.description" class="card-image"/>
        <router-link :to="card.route" class="card-description">{{ card.description }}</router-link>
        <p class="texts">{{ card.texts }}</p>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'ContentCards',
    data() {
      return {
        cards: [
        { image: require('../images/card1.png'), description: '에너지 전환은 미국에서 PE 기업들에게 엄청난 기회를 제공하고 있습니다.', route: '/PageData19' ,texts:'이사회 인텔리전스'},
{ image: require('../images/card2.png'), description: '2024년을 위한 기술 시장 기대치 재설정', route: '/PageData19' ,texts:'이사회 인텔리전스'},
{ image: require('../images/card3.png'), description: 'AI가 대체 데이터에 대한 접근을 민주화하는 방법', route: '/PageData19' ,texts:'이사회 인텔리전스'},
{ image: require('../images/card4.png'), description: '제퍼리스의 경제학자 모히트 쿠마르는 2024년에 양마켓 잠재력을 보고 있습니다.', route: '/PageData19' ,texts:'큰 그림'}
],
      };
    },
  }
  </script>
  
  <style scoped>
  .texts{
    color: rgb(39, 69, 133);
    text-align: center;
  }
  .content-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }
  
  .card {
    width: 23%; 
    height: auto;
    margin: 1%;
    transition: transform 0.3s ease;
  }
  
  .card:hover {
    transform: scale(1.05); 
  }
  
  .card:hover .card-description {
    text-decoration: underline; 
  }
  
  .card-image {
    width: 100%;
    height: auto;
  }
  
  .card-description {
    display: block;
    text-align: center;
    text-decoration: none;
    color: inherit;
    font-size: 30px;
  }
  
  @media (max-width: 768px) {
    .content-cards {
      flex-direction: column;
    }
    .card {
      width: auto; 
      margin: 10px 0;
    }
  }
  </style>
  